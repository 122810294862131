<template>
    <div>
        <div class="catering-stats-filter d-flex justify-content-between mb-3">
            <div>
                <h2>Statistika</h2>
            </div>
            <div>
                <div class="d-flex">
                    <div class="form-floating">
                        <select name="days-view" id="catering-stats-view" class="form-control" v-model="statsView" placeholder="Prikaz" @change="handleViewChange">
                            <option value="BY_DAYS">Po danima</option>
                            <option value="BY_MONTHS">Po mesecima</option>
                            <!-- <option value="MONTH_WORK_ORDERS">Mesec detaljno</option> -->
                        </select>
                        <label for="woAgreedValue">Prikaz</label>
                    </div>

                    <template>
                        <div v-if="statsView == 'BY_DAYS' || statsView == 'MONTH_WORK_ORDERS'" class="form-floating">
                                <input type="month" class="form-control" id="statsMonthInput" v-model="selectedMonth" placeholder="Period" @change="handleMonthInputChange" min="2021-07" :max="currentMoment.format('YYYY-MM')" required>
                                <label for="woEndDate">Mesec</label>
                                <div class="invalid-feedback">
                                    Unesite period
                                </div>
                        </div>
                        <div v-else-if="statsView == 'BY_MONTHS'" class="form-floating">

                            <select id="dob" class="form-control" @change="handleChangeYear" v-model="selectedYear">
                                <option value="0">Year:</option>
                                <option v-for="year in availableYears" :key=year :value="year">{{ year }}</option>
                            </select>     
                            <label for="woEndDate">Godina</label>
                            <div class="invalid-feedback">
                                Unesite period
                            </div>
                        </div>
                    </template>



                    <div class="form-floating" style="width: 200px;">
                        <select name="days-view" id="catering-stats-value" class="form-control" v-model="statsValue" placeholder="Vrednosti" @change="handleValueChange">
                            <option value="COUNT">Količina</option>
                            <option value="PRICE">Cena obroka</option>
                        </select>
                        <label for="woAgreedValue">Vrednosti</label>
                    </div>
                    
                </div>
                <div class="mt-2">
                    <div class="form-floating">
                        <select type="date" class="form-control" id="workOrders" v-model="selectedWorkOrder" placeholder="Radni nalog" @change="handleWorkOrderChange">
                                <option disabled :value="null">Izaberite radni nalog</option>
                                <option :value="-1" :key="-1">SVI Nalozi</option>
                                <option v-for="wo in allActiveWorkOrders" :value="wo" :key="wo.id">{{wo.name}}</option>
                        </select>
                        <label for="woAgreedValue">Radni nalog</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-12 control-section">
            <div>
                <ejs-grid
                    ref="cateringServicesStats"
                    :dataSource="cateringServices" 
                    class="all-catering-services"
                    :allowFiltering='true'
                    :allowSorting='true'
                    :showColumnMenu='true'
                    :filterSettings='filterSettings'
                    :recordDoubleClick="employeesDBClick"
                    :rowSelected="employeeSelect"
                    :rowDeselected="employeeDeselect"
                    :toolbar='toolbar'
                    :toolbarClick='handleToolbarClick'
                    :rowDataBound='rowDataBound'
                    :allowResizing='true'
                    :allowReordering='true'
                    :allowExcelExport='true'
                    :allowPdfExport='true'
                    >
                    <e-columns>
                        <e-column field='' headerText='#' :template="rowIndexTemplate" width='60' textAlign='Right' :visible='true' ></e-column>

                        <e-column field='id' headerText='ID' width='120' textAlign='Left' :isPrimaryKey='true' :visible='false' ></e-column>
                        <e-column field='name' headerText='Naziv restorana' width='120' textAlign='Left' ></e-column>
                        <e-column field='location' headerText='Lokacija' width='120' textAlign='Left' :visible='false'></e-column>
                        <e-column field='phone' headerText='Broj telefona' width='150' :visible='false'></e-column>
                        <e-column field='price' headerText='Cena obroka' width='150' :visible='false'></e-column>

                        
                        <template v-if="statsView == 'BY_MONTHS'">
                            <e-column field='stats.total'  headerText='Total' width='120' :visible='true'></e-column>
                            <e-column field='stats.1'  headerText='Jan' width='90' :visible='true'></e-column>
                            <e-column field='stats.2'  headerText='Feb' width='90' :visible='true'></e-column>
                            <e-column field='stats.3'  headerText='Mar' width='90' :visible='true'></e-column>
                            <e-column field='stats.4'  headerText='Apr' width='90' :visible='true'></e-column>
                            <e-column field='stats.5'  headerText='Maj' width='90' :visible='true'></e-column>
                            <e-column field='stats.6'  headerText='Jun' width='90' :visible='true'></e-column>
                            <e-column field='stats.7'  headerText='Jul' width='90' :visible='true'></e-column>
                            <e-column field='stats.8'  headerText='Avg' width='90' :visible='true'></e-column>
                            <e-column field='stats.9'  headerText='Sep' width='90' :visible='true'></e-column>
                            <e-column field='stats.10' headerText='Okt' width='90' :visible='true'></e-column>
                            <e-column field='stats.11' headerText='Nov' width='90' :visible='true'></e-column>
                            <e-column field='stats.12' headerText='Dec' width='90' :visible='true'></e-column>
                        </template>
                        <template v-if="statsView == 'BY_DAYS'">
                            <e-column field='stats.total'  headerText='Total' width='120' :visible='true'></e-column>
                            <e-column v-for="dateIter, index in availabvleDays" :key="index" :field="'stats.' + (index + 1)" type="number" :headerText='index + 1' width='90' :visible='true'></e-column>
                        </template>
                    </e-columns>
                    <e-aggregates>
                        <e-aggregate>
                            <e-columns>
                                <template v-if="statsView == 'BY_MONTHS'">
                                    <e-column type="Sum" field='stats.total'></e-column>
                                    <e-column type="Sum" field='stats.1' ></e-column>
                                    <e-column type="Sum" field='stats.2' ></e-column>
                                    <e-column type="Sum" field='stats.3' ></e-column>
                                    <e-column type="Sum" field='stats.4' ></e-column>
                                    <e-column type="Sum" field='stats.5' ></e-column>
                                    <e-column type="Sum" field='stats.6' ></e-column>
                                    <e-column type="Sum" field='stats.7' ></e-column>
                                    <e-column type="Sum" field='stats.8' ></e-column>
                                    <e-column type="Sum" field='stats.9' ></e-column>
                                    <e-column type="Sum" field='stats.10'></e-column>
                                    <e-column type="Sum" field='stats.11'></e-column>
                                    <e-column type="Sum" field='stats.12'></e-column>
                                    <e-column v-for="dateIter, index in datesArray" :key="index" type="Sum" :field="'carnet.' + dateIter + '.work_hours'"></e-column>
                                </template>
                                <template v-if="statsView == 'BY_DAYS'">
                                    <e-column type="Sum" field='stats.total'></e-column>
                                    <e-column v-for="dateIter, index in availabvleDays" :key="index" :field="'stats.' + (index + 1)" type="Sum" ></e-column>
                                </template>

                            </e-columns>
                        </e-aggregate>
                    </e-aggregates>
                </ejs-grid>
            </div>
        </div>
    </div>
</template>

<script>
    import { Sort, Page, ColumnMenu, Filter, Group, Toolbar, Reorder, Resize, PdfExport, ExcelExport, Aggregate } from "@syncfusion/ej2-vue-grids";
    // import ModalAddEditCateringService from "@/components/modals/catering/ModalAddEditCateringService.vue";
    import { Modal } from 'bootstrap'
    import UtilService from '@/service/UtilService.js';
    import RowIndexTemplate from '@/components/grid-templates/RowIndex.vue';
    import CateringService from '@/service/CateringService.js';
    import {mapState} from 'vuex'
    import { PdfStandardFont, PdfFontFamily, PdfFontStyle } from '@syncfusion/ej2-pdf-export';

    export default {
        // components: { ModalAddEditCateringService },
        data() {
            return {
                modalAddEditCateringService: null,
                editCateringService: undefined,

                filterSettings: { type: "Excel" },
                toolbar: [

                    { text: 'Exprot Excel', tooltipText: 'Izvezi Excel', prefixIcon: 'e-excelexport', id: 'exportExcel', align: 'Right'},
                    { text: 'Exprot PDF', tooltipText: 'Izvezi PDF', prefixIcon: 'e-pdfexport', id: 'exportPDF', align: 'Right'},
                    { text: 'Print', tooltipText: 'Štampaj', prefixIcon: 'e-print', id: 'print', align: 'Right'},
                    ],
                // toolbar: [
                //         { text: 'Dodaj', tooltipText: 'Dodaj', prefixIcon: 'e-add', id: 'addCateringService' }, 
                //         { text: 'Izmeni', tooltipText: 'Izmeni', prefixIcon: 'e-edit', id: 'editCateringService', disabled: false}, 
                //         { text: 'Obriši', tooltipText: 'Obriši', prefixIcon: 'e-delete', id: 'deleteEmployee', disabled: false},
                //         { text: 'Povrati', tooltipText: 'Povrati', prefixIcon: 'e-delete', id: 'restoreEmployee', visible: false},
                        
                //         { text: 'Prikaži obrisane', tooltipText: 'Prikaži neaktivne', prefixIcon: 'e-insert-column-right', id: 'showDeleted', align: 'Right'},
                //         { text: 'Sakrij obrisane', tooltipText: 'Sakrij neaktivne', prefixIcon: 'e-delete-column-right', id: 'hideDeleted', align: 'Right', visible: false}
                //         ],


                
                

                rowIndexTemplate() {
                    return {
                        template: {
                            extends: RowIndexTemplate,
                        }
                    }
                },

                cateringServices: [],

                statsView: "BY_MONTHS",
                statsValue: "COUNT",
                selectedYear: new Date().getFullYear(),
                selectedMonth: UtilService.formatDatePattern(UtilService.getMoment(), "YYYY-MM"),
                selectedWorkOrder: -1,
            }
        },

        provide: {
            grid: [Sort, Page, ColumnMenu, Filter, Group, Toolbar, Reorder, Resize, PdfExport, ExcelExport, Aggregate  ]
        },

        computed: {

            ...mapState([
                'allWorkOrders',
            ]),

            todayDate() {
                return UtilService.formatDatePattern(UtilService.getMoment())
            },

            availableYears () {
                const year = new Date().getFullYear()
                return Array.from({length: year - 2019}, (value, index) => 2020 + index)
            },

            currentMoment() {
                return UtilService.getMoment();
            },

            allActiveWorkOrders() {
                return this.allWorkOrders.filter(x => !x.real_end_date || x.real_end_date > this.todayDate)
            },

            availabvleDays() {
                let start = UtilService.convertMoment(this.selectedMonth);
                let end = UtilService.convertMoment(start).add(1, 'month').subtract(1, 'day')

                return UtilService.intervalToDatesArray(start, end)
            },
        },

        methods: {

            employeesDBClick(event) {
                console.log(event);
            },

            async employeeSelect(event) {
                console.log(event);
            },

            async employeeDeselect(event) {
                console.log(event);
            },

            clickHandler(args) {
                console.log(args);
                if (args.item.id === 'addCateringService') {
                    this.showAddCateringService()
                }

                else if (args.item.id === "editCateringService"){
                    let selected = (this.$refs.cateringServices.ej2Instances.getSelectedRecords());
                    if (selected.length > 0) selected = selected[0];
                    else return;

                    this.openUpdateModal(selected)
                }

            },


            showAddCateringService() {
                this.editCateringService = undefined
                this.modalAddEditCateringService.show();   
            },

            openUpdateModal(cateringService) {
                console.log(cateringService);
                this.editCateringService = JSON.parse(JSON.stringify(cateringService));                
                this.modalAddEditCateringService.show();   
            },

            rowDataBound() {
                return
            },
            
            getRequestParams() {
                let params = {
                    'view': this.statsView,
                    'values': this.statsValue
                }

                if (this.selectedWorkOrder && this.selectedWorkOrder != -1) 
                    params.work_order_id = this.selectedWorkOrder.id

                if (this.statsView == "BY_DAYS") {
                    params.start_date = this.selectedMonth
                }
                else if (this.statsView == "BY_MONTHS") {
                    params.start_date = this.selectedYear
                }

                return params
            },

            loadCateringServicesStats() {
                let params = this.getRequestParams()

                this.$refs.cateringServicesStats?.showSpinner()
                if (this.cateringServices && this.cateringServices.length > 0){
                    this.cateringServices.forEach(cs => {
                        cs.stats = undefined
                    }); 
                }

                CateringService.getAllCateringServicesStats(params).then(async (result)=>{
                    this.cateringServices = result.data;
                    this.$refs.cateringServicesStats?.hideSpinner()

                });
            },

            handleViewChange() {
                this.loadCateringServicesStats()
            },

            handleValueChange() {
                this.loadCateringServicesStats()
            },

            handleMonthInputChange() {
                this.loadCateringServicesStats()
            },

            handleWorkOrderChange() {
                this.loadCateringServicesStats()
            },

            handleChangeYear() {
                this.loadCateringServicesStats()
            },

            //////////////// 
            
            async loadAllWorkOrders() {
                await this.$store.dispatch('loadAllWorkOrders', this.workOrderForm).catch((error) => {
                    alert("neuspesno ucitavanje radnih naloga");
                    console.log(error);
                });
            },

            handleToolbarClick(args) {

                let exportProperties = {
                    fileName: "Ketring statistika.pdf",
                    theme: {
                        header: {font:  new PdfStandardFont(PdfFontFamily.Helvetica, 11, PdfFontStyle.Bold)},
                        caption: { font: new PdfStandardFont(PdfFontFamily.Helvetica, 9) },
                        record: { font: new PdfStandardFont(PdfFontFamily.Helvetica, 10) }
                    }
                };


                let excelProperties = {
                    fileName: "Ketring statistika.xlsx",
                };

                switch (args.item.id) {
                    case 'exportExcel':
                        this.$refs.cateringServicesStats.excelExport(excelProperties);
                        break;
                    case 'exportPDF':
                        this.$refs.cateringServicesStats.pdfExport(exportProperties);
                        break;
                    case 'print':
                        this.$refs.cateringServicesStats.print();
                        break;

                    default:
                        break;
                }

            },
        
        },

        mounted() {
            this.modalAddEditCateringService = new Modal(document.getElementById('modalAddEditCateringService'));
        },

        created() {
            this.loadCateringServicesStats();
            this.loadAllWorkOrders();
        }

    }
</script>

<style>

</style>